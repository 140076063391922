import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import Box from "@mui/material/Box";
import { modifyCustomer, addCustomer } from "../../domain/apis/customerService";
import _ from "lodash";
import { useEffect } from "react";
import { FormUIItem } from '../commons';

function CustomerModifyComponent({ show, handleClose, data }) {

  var formUI = {};
  useEffect(() => {
    if (!data) {
      formUI = {

      };
    } else {
      formUI = data;
    }
  }, [data]);

  const handleUpdateForm = (key, value) => {
    formUI[key] = value;
  };

  const onModify = () => {
    if (data) {
      modifyCustomer(formUI).subscribe({
        next: ({ data }) => {
          handleClose(true);
        },
      });
      return;
    }
    addCustomer(formUI).subscribe({
      next: ({ data }) => {
        handleClose(true);
      },
    });
  };

  return (
    <>
      <Modal
        show={show}
        onHide={() => handleClose()}
        centered
        style={{ zIndex: 10000 }}
      >
        <Modal.Header>
          <div
            style={{ display: "flex", justifyContent: "center", width: "100%" }}
          >
            {data && <Modal.Title>Chỉnh sửa khách hàng</Modal.Title>}
            {!data && <Modal.Title>Thêm khách hàng</Modal.Title>}
          </div>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Box sx={{ display: "flex", flexWrap: "wrap" }}>
              <FormUIItem
                label={"Họ tên"}
                code={"fullName"} required
                initValue={_.get(data, "fullName")}
                handleUpdate={handleUpdateForm} />
              <FormUIItem
                label={"Email"}
                code={"email"}
                initValue={_.get(data, "email")}
                handleUpdate={handleUpdateForm} />
              <FormUIItem
                label={"Chức vụ"}
                code={"position"}
                initValue={_.get(data, "position")}
                handleUpdate={handleUpdateForm} />
            </Box>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => handleClose()}>
            Hủy
          </Button>
          <Button variant="primary" onClick={() => onModify()}>
            Lưu
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default CustomerModifyComponent;
