import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import Box from "@mui/material/Box";
import { modifyVPS, addVPS } from "../../domain/apis/vpsService";
import { getServers } from "../../domain/apis/serverService";
import _ from "lodash";
import { useEffect } from "react";
import { FormUIItem } from "../commons";
import Row from "react-bootstrap/Row";
import FormControl from "@mui/material/FormControl";
import Col from "react-bootstrap/Col";
import { useState } from 'react';

function ComboBox({ value, data, onItemChange }) {
  var optionRows = [<option value={"none"}>--- Chọn máy chủ ---</option>];
  if (data != null) {
    data.forEach((item, index) => {
      optionRows.push(
        <option key={item.id} value={item.id}>{item.name}</option>
      );
    });
  }
  return (
    <select
      id="cat-combobox"
      defaultValue={"none"}
      value={value}
      className="form-select"
      onChange={($this) => {
        var value = $this.target.value;
        onItemChange(value)
      }}>
      {optionRows}
    </select>

  );
}

function VPSModifyComponent({ show, handleClose, data }) {
  const [servers, setServers] = useState([]);
  const [serverValue, setServerValue] = useState("all");

  function fetchServers() {
    getServers().subscribe({
      next({ data }) {
        const { value } = data;
        setServers(value);
      },
      error(err) {
        console.log(err);
      },
    });
  }

  var formUI = {};
  useEffect(() => {
    fetchServers();

    if (!data) {
      formUI = {
        core: 4,
        ram: 4,
        storage: 80,
      };
    } else {
      formUI = data;
    }
  }, [data]);

  const handleUpdateForm = (key, value) => {
    formUI[key] = value;
  };

  const onModify = () => {
    formUI.serverID = serverValue;
    if (data) {
      modifyVPS(formUI).subscribe({
        next: ({ data }) => {
          handleClose(true);
        },
      });
      return;
    }
    addVPS(formUI).subscribe({
      next: ({ data }) => {
        handleClose(true);
      },
    });
  };

  return (
    <>
      <Modal
        show={show}
        onHide={() => handleClose()}
        centered
        style={{ zIndex: 10000 }}
      >
        <Modal.Header>
          <div
            style={{ display: "flex", justifyContent: "center", width: "100%" }}
          >
            {data && <Modal.Title>Chỉnh sửa VPS</Modal.Title>}
            {!data && <Modal.Title>Thêm VPS</Modal.Title>}
          </div>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Box sx={{ display: "flex", flexWrap: "wrap" }}>
              <FormControl fullWidth sx={{ m: 1 }}>
                <Row>
                  <Col className="col-6 col-md-4">Máy chủ</Col>
                  <Col className="col-12 col-sm-6 col-md-8">
                    <ComboBox
                      value={serverValue}
                      data={servers}
                      onItemChange={(value) => {
                        setServerValue(value);
                      }} />
                  </Col>
                </Row>
              </FormControl>

              <FormUIItem
                label={"Tên"}
                code={"name"}
                required
                initValue={_.get(data, "name")}
                handleUpdate={handleUpdateForm}
              />
              <FormUIItem
                label={"Mô tả"}
                code={"description"}
                initValue={_.get(data, "description")}
                handleUpdate={handleUpdateForm}
              />
              <FormUIItem
                label={"VMID"}
                code={"vmid"}
                initValue={_.get(data, "vmid")}
                handleUpdate={handleUpdateForm}
              />
              <FormUIItem
                label={"VM Alias"}
                code={"vmAlias"}
                initValue={_.get(data, "vmAlias")}
                handleUpdate={handleUpdateForm}
              />
              <FormUIItem
                label={"CPU CORE"}
                code={"core"}
                unit={"core"}
                type={"number"}
                initValue={_.get(data, "core")}
                handleUpdate={handleUpdateForm}
              />
              <FormUIItem
                label={"RAM"}
                code={"ram"}
                unit={"GB"}
                type={"number"}
                initValue={_.get(data, "ram")}
                handleUpdate={handleUpdateForm}
              />
              <FormUIItem
                label={"Ổ đĩa cứng"}
                code={"storage"}
                unit={"GB"}
                type={"number"}
                initValue={_.get(data, "storage")}
                handleUpdate={handleUpdateForm}
              />
              <FormUIItem
                label={"IP"}
                code={"ip"}
                initValue={_.get(data, "ip")}
                handleUpdate={handleUpdateForm}
              />
              <FormUIItem
                label={"Băng thông"}
                code={"bandwidth"}
                unit={"MBps"}
                initValue={_.get(data, "bandwidth")}
                handleUpdate={handleUpdateForm}
              />
            </Box>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => handleClose()}>
            Hủy
          </Button>
          <Button variant="primary" onClick={() => onModify()}>
            Lưu
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default VPSModifyComponent;
